<template>
  <div class="newestProblem">
    <el-card shadow="hover">
      <template #header>
        <h4 style="margin: 0">最新题目</h4>
      </template>
      <div>
        <div
          class="item"
          v-for="problem in problemList"
          :title="problem.description"
          :key="problem.problemId"
          @click="handleClick(problem)"
        >
          <p style="font-size: 1.2em;color: gray">{{ problem.title }}</p>
          <div>
            <div style="margin-bottom: 5px">
              <el-tag
                v-for="tag in problem.tags.split(',').slice(0, 3)"
                :key="tag"
                size="small"
                type="success"
                style="margin-left: 5px;"
                >{{ tag }}</el-tag
              >
            </div>
            <el-text type="info">{{ problem.formatTime }}</el-text>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { api } from "@/utils/requests";
let problemList = reactive([]);
const handleClick = (p) => {
  window.open('/game.html?problemId=' + p.problemId, '_blank');
}
onMounted(() => {
  api.get("/problem/getTop10").then((res) => {
    if (res.data.code == 200) {
      Object.assign(problemList, res.data.data);
    }
  });
});
</script>

<style scoped>
.item {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.item p {
  margin: 0;
}
.item > div {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.item:hover {
  background-color: #f5f5f5;
}
</style>
<style>
.newestProblem .el-card__body {
  padding: 0;
}
</style>
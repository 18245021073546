<template>
  <div class="newestDiscussion">
    <el-card shadow="hover">
      <template #header>
        <h4 style="margin: 0">最新讨论</h4>
      </template>
      <div class="grid">
        <el-card
          v-for="post in postData.list"
          :key="post.postId"
          class="post-card"
          shadow="none"
          @click="showPostDialog(post)"
        >
          <template #header>
            <span style="margin-right: 5px">{{ post.nick }}</span>
            <el-tag
              type="warning"
              v-if="post.username === getCookieValue('username')"
              style="margin-right: 5px"
              >自己</el-tag
            >
            <span style="float: right">{{ formatTime(post.time) }}</span>
          </template>
          <p>{{ post.title }}</p>
        </el-card>
      </div>

      <el-dialog
        v-model="dialog"
        width="70%"
        style="min-height: 100%; margin-top: 0; margin-bottom: 0"
      >
        <ReplyDialog :post="post" />
      </el-dialog>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import { api } from "@/utils/requests.js";
import ReplyDialog from "@/component/ReplyDialog.vue";
import { getCookieValue } from "@/utils/cookies";

let dialog = ref(false);
let postData = reactive({});
let post = reactive({});
let replyDialog = ref(null);
onMounted(() => {
  api
    .post("/post/list", {
      limit: 10,
      page: 1,
      sortInfo: { prop: "time", order: -1 },
    })
    .then((res) => {
      Object.assign(postData, res.data.data);
    });
});

const showPostDialog = (p) => {
  // Implement logic to show the post details in the dialog
  // You can use the 'post' object to access the details of the selected post
  Object.assign(post, p);
  dialog.value = true;
};

const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.post-card {
  cursor: pointer;
  transition: transform 0.3s ease;
}
.post-card:hover {
  transform: scale(1.05);
}
</style>

<template>
  <div class="info">
    <el-descriptions title="个人信息" :column="1" direction="vertical" border>
      <template #extra>
        <el-button type="primary" @click="changePwd = true" style="margin-right: 50px">
          修改密码
        </el-button>
      </template>
      <el-descriptions-item label="用户名">{{ userInfo.username }}</el-descriptions-item>
      <el-descriptions-item label="昵称">
        {{ userInfo.nick }}
        <el-button @click="changeNick = true" style="margin-left: 30px">修改昵称</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="邮箱">
        {{ userInfo.email }}
        <el-button v-if="!userInfo.email" @click="bindEmailDialog = true" style="margin-left: 30px">
          绑定邮箱
        </el-button>
      </el-descriptions-item>
      <el-descriptions-item label="用户Id">{{ userInfo.userId }}</el-descriptions-item>
      <el-descriptions-item label="用户类型">
        <el-tag :type="['warning', 'success', 'primary'][userInfo.userType]" disable-transitions>
          {{ ["管理员", "学生", "教师"][userInfo.userType] }}
        </el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <!-- 修改密码对话框 -->
    <el-dialog class="changePwd" v-model="changePwd" title="修改密码" width="400px">
      <el-form :rules="pwdRules" :model="pwdInfo" ref="pwdRef" style="width: 100%">
        <el-form-item prop="password">
          <el-input v-model="pwdInfo.password" type="password" placeholder="密码" show-password />
        </el-form-item>
        <el-form-item prop="password1">
          <el-input v-model="pwdInfo.password1" type="password" placeholder="确认密码" show-password />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="changePwd = false">取消</el-button>
        <el-button type="primary" @click="changePwdF(pwdRef)">提交</el-button>
      </template>
    </el-dialog>

    <!-- 修改昵称对话框 -->
    <el-dialog class="changeNick" v-model="changeNick" title="修改昵称" width="400px">
      <el-form :rules="usernameRules" :model="usernameInfo" ref="usernameRef" style="width: 100%">
        <el-form-item prop="username">
          <el-input v-model="usernameInfo.username" placeholder="新昵称" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="changeNick = false">取消</el-button>
        <el-button type="primary" @click="changeNickF(usernameRef)">提交</el-button>
      </template>
    </el-dialog>

    <!-- 绑定邮箱对话框 -->
    <el-dialog class="bindEmail" v-model="bindEmailDialog" title="绑定邮箱" width="400px">
      <el-form :rules="emailRules" :model="emailInfo" ref="emailRef" style="width: 100%">
        <el-form-item prop="email">
          <el-input v-model="emailInfo.email" type="email" placeholder="邮箱" />
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="emailInfo.code" maxlength="6" placeholder="验证码">
            <template #append>
              <el-button
                size="small"
                :disabled="!emailAble"
                @click="startCountdown"
                style="width: 100px"
                :title="emailAble ? '' : '请先输入邮箱'"
              >
                {{ emailCd ? `(${countdown})` : "获取验证码" }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="bindEmailDialog = false">取消</el-button>
        <el-button type="primary" @click="bindEmailF(emailRef)">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { ElLoading, ElMessage } from "element-plus";
import { api } from "@/utils/requests";

let userInfo = reactive({
  userId: null,
  userType: null,
  username: "",
  nick: "",
  email: "",
});
let changePwd = ref(false);
let changeNick = ref(false);
let bindEmailDialog = ref(false);

let emailInfo = reactive({
  email: "",
  code: "",
});
let pwdInfo = reactive({
  password: "",
  password1: "",
});
let usernameInfo = reactive({
  username: "",
});
let usernameRef = ref();
let usernameRules = reactive({
  username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
});
let emailRef = ref();
let emailAble = ref(false);
let emailCd = ref(false);
let emailStatus = ref(false);
let countdown = ref(60); // 倒计时时间
let timer; // 定时器

const emailRules = reactive({
  email: [{ validator: validateEmail, trigger: "blur" }],
  code: [{ validator: validateCode, trigger: "blur" }],
});
const pwdRules = reactive({
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
  password1: [{ validator: validatePassword1, trigger: "change" }],
});

// 验证规则函数 - 验证邮箱格式
function validateEmail(rule, email, callback) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailRegex.test(email)) {
    emailAble.value = false;
    callback(new Error("请输入有效的邮箱地址"));
  } else {
    emailAble.value = true;
    callback();
  }
}

// 验证规则函数 - 验证验证码
function validateCode(rule, value, callback) {
  if (!emailStatus.value) {
    callback(new Error("请先获取验证码"));
  } else if (emailInfo.code.length < 6) {
    callback(new Error("请输入完整验证码"));
  } else {
    callback();
  }
}

// 验证规则函数 - 验证两次密码输入是否一致
function validatePassword1(rule, pwd, callback) {
  if (pwd == "") {
    callback(new Error("请再次输入密码"));
  } else if (pwd != pwdInfo.password) {
    callback(new Error("两次输入不一致"));
  } else {
    callback();
  }
}

// 获取验证码倒计时逻辑
function startCountdown() {
  api.post("/user/emailVerify", { email: emailInfo.email }).then((res) => {
    if (res.data.code == 200) {
      ElMessage.success("验证码发送成功");
    } else {
      console.log(res);
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });

  emailAble.value = false;
  emailCd.value = true;
  emailStatus.value = true;
  timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(timer);
      emailAble.value = true;
      emailCd.value = false;
      countdown.value = 60; // 重置倒计时时间
    }
  }, 1000);
}

// 发送验证码逻辑
function sendVerificationCode(formEl) {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      startCountdown();
    }
  });
}

// 绑定邮箱逻辑
function bindEmailF(formEl) {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      const loading = ElLoading.service({
        text: "绑定邮箱...",
        background: "rgba(255, 255, 255, 0.6)",
        target: ".info .bindEmail",
      });

      api
        .post("/user/bindEmail", {
          email: emailInfo.email,
          code: emailInfo.code,
        })
        .then((res) => {
          if (res.data.code == 200) {
            ElMessage.success("邮箱绑定成功");
            bindEmailDialog.value = false;
            getInfo();
          } else {
            ElMessage.error(res.data.code + ":" + res.data.message);
          }
        })
        .finally(() => {
          loading.close();
        });
    }
  });
}

// 修改密码逻辑
const changePwdF = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      const loading = ElLoading.service({
        text: "修改密码...",
        background: "rgba(255, 255, 255, 0.6)",
      });
      api
        .post("/user/changePassword", { password: pwdInfo.password })
        .then((res) => {
          if (res.data.code == 200) {
            ElMessage.success("密码修改成功");
            changePwd.value = false;
          } else {
            console.log(res);
            ElMessage.error(res.data.code + ":" + res.data.message);
          }
        })
        .finally(() => {
          loading.close();
        });
    }
  });
};

// 修改昵称逻辑
const changeNickF = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      const loading = ElLoading.service({
        text: "修改昵称...",
        background: "rgba(255, 255, 255, 0.6)",
      });
      api
        .post("/user/changeNick", { nick: usernameInfo.username })
        .then((res) => {
          if (res.data.code == 200) {
            ElMessage.success("昵称修改成功");
            changeNick.value = false;
            getInfo();
          } else {
            ElMessage.error(res.data.code + ":" + res.data.message);
          }
        })
        .finally(() => {
          loading.close();
        });
    }
  });
};

// 获取用户信息
const getInfo = () => {
  api.get("/user/info").then((res) => {
    if (res.data.code == 200) {
      Object.assign(userInfo, res.data.data);
    } else {
      console.log(res);
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
  });
};

// 组件初始化时获取用户信息
onMounted(() => {
  getInfo();
});
</script>

<style scoped>
.info {
  padding: 10px 20px;
}
</style>

<style>
.info .el-input div:first-child {
  background-color: rgba(255, 255, 255, 0.584);
  padding: 5px 10px;
}
</style>

<template>
  <el-card style="margin-bottom: 20px" @click="getReplyList()">
    <template #header>
      <span style="margin-right: 5px">{{ post.nick }}</span>
      <el-tag type="warning" v-if="post.username === getCookieValue('username')" style="margin-right: 5px">自己</el-tag>
      <el-tag style="margin-right: 5px">楼主</el-tag>
      <span style="float: right">{{ formatTime(post.time) }}</span>
    </template>
    <p>{{ post.content }}</p>
  </el-card>

  <el-button type="primary" @click="replyDialogVisible=true" style="margin-bottom: 20px">回复楼主</el-button>
  <div>
    <el-card
      v-for="reply in replyData.list"
      :key="reply.replyId"
      style="margin-bottom: 10px"
    >
      <template #header>
      <span style="margin-right: 5px">{{ reply.nick }}</span>
      <el-tag type="warning" v-if="reply.username === getCookieValue('username')" style="margin-right: 5px">自己</el-tag>
      <el-tag v-if="post.userId === reply.userId" style="margin-right: 5px">楼主</el-tag>
      <span  style="float: right">{{ formatTime(reply.time) }}</span>
      </template>
      <p>{{ reply.content }}</p>
    </el-card>
  </div>
  <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next, total, sizes"
      :total="replyData.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      @prev-click="handlePrev"
      @next-click="handleNext"
    />
  </div>
  <el-dialog
    v-model="replyDialogVisible"
    title="回复楼主"
    :visible="replyDialogVisible"
    @close="resetReplyForm"
  >
    <el-input
      v-model="content"
      type="textarea"
      :rows="4"
      placeholder="请输入回复内容"
    ></el-input>
    <template #footer>
      <el-button @click="replyDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="replyToOriginalPoster">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, defineProps, onMounted,watch } from "vue";
import { api } from "@/utils/requests";
import { ElMessage } from "element-plus";
import { getCookieValue } from "@/utils/cookies";

let { post } = defineProps(["post"]);
let replyData = reactive({});
let page = ref(1);
let limit = ref(10);

let replyDialogVisible = ref(false);
let content = ref("");

const getReplyList = () => {
  console.log(post)
  api
    .post("/reply/list", {
      page,
      limit,
      sortInfo: { prop: "time", order: 1 },
      filterInfo: { postId: post.postId },
    })
    .then((res) => {
      if (res.data.code === 200) {
        Object.assign(replyData, res.data.data);
      } else {
        ElMessage.error(res.data.code + ":" + res.data.message);
      }
    });
};

const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const handleCurrentChange = (e) => {
  page.value = e;
  getReplyList();
};

const handleSizeChange = (e) => {
  limit.value = e;
  page.value = 1;
  getReplyList();
};

const handlePrev = (e) => {
  page.value = e;
  getReplyList();
};

const handleNext = (e) => {
  page.value = e;
  getReplyList();
};

const replyToOriginalPoster = () => {
  api.post("/reply/add", { postId: post.postId, content: content.value }).then((res) => {
    if (res.data.code === 200) {
      ElMessage.success("回复成功");
      getReplyList();
    } else {
      ElMessage.error(res.data.code + ":" + res.data.message);
    }
    replyDialogVisible.value = false;
  });
};


watch(post, () => {
  getReplyList();
})
onMounted(() => {
  getReplyList();
});
</script>
